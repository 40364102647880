<style lang="scss">
#debug {
  display: none;
}

#frontend {
  .karriere-kontakt-all {
    .header-intro-generel-box {
      background-position: center center !important;
    }

    .person-box {
      position: relative;
      margin-bottom: 50px;

      .txt-box {
        text-align: left;
        padding: 60px 25px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;

        .name {
          font-family: 'Helvetica Neue LT W05_75 Bold';
          font-size: 200%;

          @media (max-width: $width-lg) {
            font-size: 150%;
          }

          @media (max-width: $width-sm) {
            font-size: 130%;
          }
        }

        .job {
          margin-top: 20px;
          margin-bottom: 20px;
          letter-spacing: 1px;
          padding-right: 40px;

          strong {
            display: block;
            font-weight: normal;
            font-family: 'Helvetica Neue LT W05 65 Medium';
          }

          span {
            display: block;
            font-size: 90%;
            line-height: 1.8;
          }
        }

        .zitat {
          font-family: 'American Typewriter ITC W04 Md';

          margin-bottom: 40px;
          font-size: 110%;

          @media (max-width: $width-sm) {
            font-size: 100%;
          }

          .zitat-icon {
            display: block;
          }
        }
      }

      .box-middle {
        border: 1px solid #878787;
      }

      .box-bottom {
        text-align: left;
        border: 1px solid #878787;
        border-top: 0;
        padding: 20px 25px;

        a {
          display: block;
          letter-spacing: 1px;
          font-size: 90%;
          position: relative;

          .icon-img {
            position: absolute;
            right: 0;
            top: 0;

            img {
              width: 30px;
              height: auto;
            }
          }

          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="karriere-kontakt-all">
    <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/karriere/header-img-top.jpg')})` }"  :headerImageText="$t('General.ansprechpartnerinnen')" />-->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb">
                <span>{{ $t('General.karriere') }}</span>
              </div>
              <h1 v-html="$t('karriere-kontakt.ansprechpartnerinnen-v2')"></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-7">
          <p class="intro-txt text-center padding-t-m padding-b-xl" v-html="$t('karriere-kontakt.intro-txt')"></p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-center align-self-center">
        <div class="col-lg-4 col-md-6">
          <!--
              <div class="person-box">
                <img src="@/assets/img/karriere/kontakt/vanessa-bange.jpg" alt="Vanessa Bange" class="img-fluid">
                  <div class="box-middle">
                          <div class="txt-box">
                      <div class="name">Vanessa Bange</div>
                        <div class="job">
                          <strong>HR-Business-Partnerin</strong>

                          <span>Styria Media Group </span>
                          <span>Styria Media International </span>
                          <span>Styria Facility Management</span>
                        </div>
                        <div class="zitat">
                          <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.vanessa-bange-txt') }}
                        </div>

                      </div>
                    </div>
                    <div class="box-bottom">
                      <a href="mailto:vanessa.bange@styria.com">vanessa.bange@styria.com<span class="icon-img"><img
                            src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
                    </div>
                  </div>

                  -->
          <!-- TEMPLATE ENDE-->
          <!--<div class="person-box">
            <img src="@/assets/img/karriere/kontakt/mario-renn.jpg" alt="Mario Renn" class="img-fluid">
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Mario Renn</div>
                <div class="job">
                  <strong>HR-Business-Partner</strong>
                  <span>Kleine Zeitung</span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.mario-renn-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:mario.renn@styria.com">mario.renn@styria.com<span class="icon-img"><img
                    src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid" /></span></a>
            </div>
          </div>
        -->

          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/nicole-windisch.jpg" alt="Nicole Windisch" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Nicole Windisch</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>Die Presse</span>
                </div>
                <div class="zitat"><Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.birgit-greimel-txt') }}</div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:nicole.windisch@styria.com"
                >nicole.windisch@styria.com<span class="icon-img"
                  ><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
              ></a>
            </div>
          </div>
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/alexandra-richter.jpg" alt="Alexandra Richter" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Alexandra Richter</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>Druck Carinthia</span>
                  <span>Druck Styria</span>
                </div>
                <div class="zitat"><Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.deniz-praun-txt') }}</div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:alexandra.richter@styria.com"
                >alexandra.richter@styria.com<span class="icon-img"><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
              ></a>
            </div>
          </div>
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/marie-fritz.jpg" alt="Marie Fritz" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Marie Fritz</div>
                <div class="job">
                  <strong>Junior HR-Business-Partnerin</strong>
                  <span>Die Furche</span>
                  <span>Styria Buchverlage</span>
                </div>
                <div class="zitat"><Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.marie-fritz-txt') }}</div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:marie.fritz@styria.com"
                >marie.fritz@styria.com<span class="icon-img"><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/kerstin-roth.jpg" alt="Kerstin Roth" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Kerstin Roth</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>Call & Mail</span>
                  <span>rca radio content Austria</span>
                  <span>Styria Marketing Services</span>
                  <span>Styria Medienhaus Sport</span>
                </div>
                <div class="zitat"><Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.kerstin-roth-txt') }}</div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:kerstin.roth@styria.com"
                >kerstin.roth@styria.com<span class="icon-img"><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
              ></a>
            </div>
          </div>

          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/martina-glauninger.jpg" alt="Martina Glauninger" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Martina Glauninger</div>
                <div class="job">
                  <strong>{{ $t('karriere-kontakt.martina-glauninger-job') }}</strong>
                  <span>Antenne Kärnten</span>
                  <span>Antenne Steiermark</span>
                  <span>Radio Flamingo </span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />
                  {{ $t('karriere-kontakt.martina-glauninger-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:martina.glauninger@antenne.at"
                >martina.glauninger@antenne.at<span class="icon-img"
                  ><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
              ></a>
            </div>
          </div>

          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/Annalena-Pfeifer-Weidenhaus.jpg" alt="Annalena Pfeifer-Weidenhaus" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Annalena Pfeifer-Weidenhaus</div>
                <div class="job">
                  <strong>Recruiting & Employer Branding </strong>
                  <span>willhaben </span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.annalena-pfeifer-weidenhaus-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:anna.weidenhaus@willhaben.at"
                >anna.weidenhaus@willhaben.at<span class="icon-img"
                  ><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
              ></a>
            </div>
          </div>
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/elke-hrassak.jpg" alt="Elke Hrassak" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Elke Hrassak</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>COPE</span>
                  <span>Styria Finance & People</span>
                  <span>Styria Digital Marketplaces</span>
                  <span>Styria Facility Management</span>
                  <span>Styria Media Group</span>
                  <span>Styria Media International</span>
                  <span>Styria Digital Services</span>
                </div>
                <div class="zitat"><Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.elke-hrassak-txt') }}</div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:elke.hrassak@styria.com"
                >elke.hrassak@styria.com<span class="icon-img"><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
              ></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/jelena-poljicanin.jpg" alt="Jelena Poljicanin" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Jelena Poljicanin</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>24sata</span>
                  <span>Pixsell</span>
                  <span>Fides Bilanca </span>
                  <span>Styria Digital Development</span>
                  <span>Styria IT Solutions (CRO)</span>
                  <span>Styria medijski servisi</span>
                  <span>Tiskara Zagreb </span>
                  <span>Večernji list </span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />
                  {{ $t('karriere-kontakt.jelena-poljicanin-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:jelena.poljicanin@styria.hr"
                >jelena.poljicanin@styria.hr<span class="icon-img"
                  ><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
              ></a>
            </div>
          </div>

          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/britta-ebenberger.jpg" alt="Britta Ebenberger" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Britta Ebenberger</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>Kleine Zeitung</span>
                  <span>Styria IT Solutions </span>
                </div>
                <div class="zitat">
                  <Icon :path="mdiFormatQuoteClose" class="zitat-icon" />
                  {{ $t('karriere-kontakt.britta-ebenberger-txt') }}
                </div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:britta.ebenberger@styria.com"
                >britta.ebenberger@styria.com<span class="icon-img"
                  ><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
              ></a>
            </div>
          </div>

          <div class="person-box">
            <img src="@/assets/img/karriere/kontakt/andrea-snopek.jpg" alt="Andrea Snopek" class="img-fluid" />
            <div class="box-middle">
              <div class="txt-box">
                <div class="name">Andrea Snopek</div>
                <div class="job">
                  <strong>HR-Business-Partnerin</strong>
                  <span>Accura Logistik</span>
                  <span>connect724 </span>
                  <span>redmail Logistik & Zustellservice</span>
                </div>
                <div class="zitat"><Icon :path="mdiFormatQuoteClose" class="zitat-icon" />{{ $t('karriere-kontakt.andrea-snopek-txt') }}</div>
              </div>
            </div>
            <div class="box-bottom">
              <a href="mailto:andrea.snopek@redmail.at"
                >andrea.snopek@redmail.at<span class="icon-img"
                  ><img src="@/assets/img/header-kontakt-icon.svg" alt="Kontakt" class="img-fluid"/></span
              ></a>
            </div>
          </div>
          <!-- TEMPLATE -->
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';

import { mdiChevronUp, mdiFormatQuoteClose } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Ansprechpartner | Styria Media Group',
      keywords: ['kontakt', 'karriere', 'ansprechpartner', 'hr', 'human resources', 'styria'],
      description:
        'Wie gut, zu wissen, wohin man sich wenden kann! Hier gibt es alle Ansprechpartner:innen rund um Jobsuche und Bewerbung auf einen Blick.',
      image: require('@/assets/img/og/karriere.png'),
    },
    en: {
      title: 'contact | Styria Media Group',
      keywords: ['contact', 'career', 'contact person', 'hr', 'human resources', 'styria'],
      description: 'How good it is to know where to turn! Here you can find all contact persons for job search and application at a glance.',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
      mdiFormatQuoteClose,
    };
  },
});
</script>
